.container {
  text-align: center;
}

.brand {
  width: 40%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.address {
  padding-top: 20px;
}

.address a {
  text-decoration: none;
  font-size: 20px;
  color: #606060;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
